import { onError } from '@apollo/client/link/error'
import { GraphQLFormattedError } from 'graphql'
import { logger } from '@/utils/logging'

/**
 * Generic ApolloLink for error logging. Attempts to expose more of
 * the two types of error responses that ApolloClient will report
 * and send that data to DataDog.
 * @see https://www.apollographql.com/docs/react/data/error-handling
 */
export function logErrorLink(clientName: string) {
  return onError((errResponse) => {
    const { graphQLErrors, networkError, operation, forward, response } = errResponse

    const logContext = {
      clientName,
      context: operation.getContext(),
      operationName: operation.operationName,
      query: operation.query,
      response,
      variables: operation.variables,
    }

    if (graphQLErrors) {
      if (handleExpectedErrors(operation.operationName, graphQLErrors)) {
        return
      }

      logger().error('ApolloClient logErrorLink graphQLErrors', { graphQLErrors, ...logContext })
      for (const err of graphQLErrors) {
        if (err?.extensions?.code) {
          switch (err.extensions.code) {
            case 'INTERNAL_SERVER_ERROR':
              return forward(operation)
            // Retry failed queries for Internal Server Errors
          }
        }
      }
    }

    if (networkError) {
      logger().error('ApolloClient logErrorLink networkError', { networkError, ...logContext })
    }
  })
}

export const handleExpectedErrors = (
  operationName: string,
  graphQLErrors: readonly GraphQLFormattedError[],
): boolean => {
  const isErrorExpected =
    (operationName === 'getProjectStory' && graphQLErrors.some((e) => e.message === 'bad_arg')) ||
    (operationName === 'getUserStory' && graphQLErrors.some((e) => e.message === 'bad_arg'))

  if (isErrorExpected) {
    // Expected errors should be handled in the query. Do not log an error or retry.
    return true
  }

  return false
}
