import { Maybe } from '@/types/codegen-federation'

export function hasSeasonEarlyAccess(
  earlyAccessGroup: string | undefined,
  allowedGroups?: Array<{ name?: string | null } | null> | null,
) {
  if (!earlyAccessGroup) return false

  const userGroups = allowedGroups?.flatMap((group) => group?.name) || []
  return userGroups.includes(earlyAccessGroup)
}

interface GetUserDisplayUser {
  profile?: Maybe<{
    lastName?: Maybe<string>
    firstName?: Maybe<string>
  }>
  lastName?: Maybe<string>
  firstName?: Maybe<string>
  email?: Maybe<string>
}

export const getUserFirstName = (user?: Maybe<GetUserDisplayUser>, defaultName = '') => {
  const profile = user?.profile
  const firstName = user?.firstName

  if (firstName) return firstName
  if (profile?.firstName) return profile.firstName
  return defaultName
}

export const getUserLastName = (user?: Maybe<GetUserDisplayUser>, defaultName = '') => {
  const profile = user?.profile
  const lastName = user?.lastName

  if (lastName) return lastName
  if (profile?.lastName) return profile.lastName
  return defaultName
}

export function getUserDisplayName(user?: Maybe<GetUserDisplayUser>, defaultName = ''): string {
  const profile = user?.profile
  const firstName = user?.firstName
  const lastName = user?.lastName

  if (firstName && lastName) return `${firstName} ${lastName}`
  if (profile?.firstName && profile?.lastName) return `${profile.firstName} ${profile.lastName}`
  if (profile?.firstName) return profile.firstName
  if (profile?.lastName) return profile.lastName

  return defaultName
}

export function getUserDisplayNameOrEmail(user?: Maybe<GetUserDisplayUser>, defaultName = ''): string {
  const displayName = getUserDisplayName(user)
  if (displayName) return displayName
  if (user?.email) return user.email
  return defaultName
}

export const getIsAngelEmployeeUser = (email: string | null | undefined) => {
  if (!email) return false
  return /@angel.com$/.test(email)
}
