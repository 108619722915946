export interface AdditionalHeaders {
  Authorization?: string
  'X-RID'?: string
  'X-PLATFORM'?: string
  'X-VIEWER-PROFILE-ID'?: string
}

export interface BaseClientConstructor {
  baseUrl: string
  name?: string
  version?: string
  locale?: string
  region?: string
  viewerProfileId?: string
  clientName: string
}

export const FEDERATION_CLIENT_NAME = 'angel-web'
export const FEDERATION_CLIENT_VERSION = '1.0'
