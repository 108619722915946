/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { isClient } from './shared'

const AXIOS_ERROR_KEYS: (keyof AxiosError)[] = ['code', 'isAxiosError', 'message', 'name', 'stack', 'status']

const CONFIG_KEYS: (keyof InternalAxiosRequestConfig)[] = [
  'baseURL',
  'data',
  'decompress',
  'headers',
  'maxBodyLength',
  'maxContentLength',
  'maxRedirects',
  'method',
  'params',
  'proxy',
  'responseType',
  'socketPath',
  'timeout',
  'timeoutErrorMessage',
  'url',
  'withCredentials',
  'xsrfCookieName',
  'xsrfHeaderName',
]

const RESPONSE_KEYS: (keyof AxiosResponse)[] = ['data', 'headers', 'status', 'statusText']

/**
 * This function was ported from https://github.com/org-redtea/format-axios-error/blob/master/src/core.ts
 * with some modifications.
 * For some reason installing that package was causing issues with the build, hence the port.
 * @param err
 * @returns
 */
export function formatAxiosError(err: AxiosError): any {
  const { config, response } = err

  const copy = extractFields(err, AXIOS_ERROR_KEYS)
  if (config) copy.config = extractFields(config, CONFIG_KEYS)
  if (response) copy.response = extractFields(response, RESPONSE_KEYS)

  return copy
}

function extractFields(obj: any, keys: string[]): any {
  if (typeof obj !== 'object' || !obj) return obj

  const copy: any = {}
  for (const key of keys) {
    if (obj[key]) {
      if (key === 'data') copy[key] = formatAxiosData(obj[key])
      else copy[key] = obj[key]
    }
  }

  return copy
}

function formatAxiosData(data: any): any {
  if (!isClient()) {
    if (isReadableStream(data)) {
      return '[Readable]'
    }
  }

  return data
}

function isReadableStream(data: any): boolean {
  const expectedFunctions = ['addListener', 'destroy', 'emit', 'isPaused', 'on', 'pipe', 'read', 'resume']
  const expectedBooleans = ['destroyed', 'readable']

  return Boolean(
    data &&
      typeof data === 'object' &&
      expectedFunctions.every((f) => typeof data[f] === 'function') &&
      expectedBooleans.every((b) => typeof data[b] === 'boolean'),
  )
}
