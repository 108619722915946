import React, { useMemo } from 'react'
import Link from 'next/link'
import { GuildShield } from '@/atoms/Icons/GuildShield'
import { CaptionMD, EyebrowLG, TitleMD } from '@/atoms/Text'
import { paths } from '@/constants'
import { NamedColor } from '@/constants/types'
import { useThemeContext } from '@/contexts/ThemeContext'
import { Avatar } from '@/molecules/Avatar'
import { useGuildMember, useGuildUser } from '@/services/GuildUserService'
import { useUser } from '@/services/UserService'
import { useLocale } from '@/utils/LocaleUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { getUserFirstName, getUserLastName } from '@/utils/users'

export const UserMenuHeader: React.FC = () => {
  const { t } = useTranslate('common')
  const { locale } = useLocale()
  const { user } = useUser()
  // Using deprecated method until we have those returned from the new API
  const { guildPlan, guildMemberStart, loading } = useGuildUser()
  const { isGuildMember } = useGuildMember()
  const { isDarkMode } = useThemeContext()

  const account: { name: string; color: NamedColor } = useMemo(() => {
    if (!isGuildMember) {
      return {
        name: t('freeAccount', 'Free Account'),
        color: isDarkMode ? 'core-gray-400' : 'core-gray-700',
      }
    }

    switch (guildPlan) {
      case 'GOLD':
        return {
          name: t('premium', 'Premium'),
          color: 'core-copper',
        }
      case 'SILVER':
        return {
          name: t('basic', 'Basic'),
          color: 'core-copper',
        }
      default:
        return {
          name: t('supporter', 'Supporter'),
          color: isDarkMode ? 'white' : 'core-gray-950',
        }
    }
  }, [guildPlan, t, isDarkMode, isGuildMember])

  const name = useMemo(() => {
    const firstName = getUserFirstName(user)
    const lastName = getUserLastName(user)

    if (firstName && lastName) {
      return `${firstName} ${lastName.charAt(0)}.`
    } else if (firstName) {
      return firstName
    }

    return ''
  }, [user])

  return (
    <div className="-mx-4 mb-3 flex gap-3 border-y border-gray-900 px-4 py-5 md:border-t-0">
      <div className="shrink-0">
        <Avatar size={48} />
      </div>
      <div className="flex w-full flex-wrap justify-between gap-x-3 gap-y-2">
        <div>
          <TitleMD weight="semibold" color={isDarkMode ? 'white' : 'gray-950'}>
            {name !== '' ? name : t('anonymous', 'Anonymous')}
          </TitleMD>
          {!!user?.email && <CaptionMD color={isDarkMode ? 'gray-500' : 'gray-600'}>{user.email}</CaptionMD>}
        </div>

        {!loading && (
          <div>
            <div className="flex items-center gap-1">
              {isGuildMember && <GuildShield size={12} />}
              <EyebrowLG weight="semibold" color={account.color} className="uppercase">
                {account.name}
              </EyebrowLG>
            </div>
            {isGuildMember && !!guildMemberStart && (
              <CaptionMD color={isDarkMode ? 'gray-500' : 'gray-600'}>
                {t('sinceDate', 'Since {{ date }}', {
                  date: new Date(guildMemberStart).toLocaleDateString(locale, { dateStyle: 'short' }),
                })}
              </CaptionMD>
            )}
            {!isGuildMember && (
              <Link href={paths.guild.join}>
                <CaptionMD color="core-oxide-bright">{t('unlockBenefits', 'Unlock Benefits')}</CaptionMD>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
