/* eslint-disable @typescript-eslint/no-explicit-any */
import { getAngelEnvironment, isTestEnvironment } from '../environment-utils'
import { getConsoleJsonLogger } from './consoleJsonLogger'
import { getConsoleLogger } from './consoleLogger'
import { getNoopLogger, isClient, Logger } from './shared'

let logger: Logger

/**
 * Short for "server logger".
 * @deprecated Do not use directly. Use logger() instead.
 */
export function sLogger() {
  if (!logger) {
    logger = initLogger()
  }
  return logger
}

function initLogger(): Logger {
  const fallbackLogger = getConsoleLogger(getDefaultContext)

  if (isTestEnvironment()) {
    return getNoopLogger()
  }

  if (isClient()) {
    fallbackLogger.warn('Tried to initialize the server side logger on the client. Falling back to a console logger.')
    return fallbackLogger
  }

  try {
    return getConsoleJsonLogger(getDefaultContext)
  } catch (err) {
    fallbackLogger.error('Failed to initialize the json logger. Falling back to a console logger.', err)
    return fallbackLogger
  }
}

function getDefaultContext() {
  return {
    loggerName: 'angel-web-server',
    env: getAngelEnvironment(),
    version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    timestamp: Date.now(),
  }
}
