import { type AnalyticsInstance, default as AnalyticsModule } from 'analytics'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { getRID } from '@/utils/RIDUtil'
import { getAngelEnvironment } from '@/utils/environment-utils'
import { omitUndefinedAndNulls, omitUndefineds } from '@/utils/object'
import { getObjectFromSessionStorage } from '@/utils/session-storage'
import { appName, buildId } from './constants'
import { branchAnalyticsPlugin } from './plugins/branch-plugin'
import { customAnonIdPlugin } from './plugins/custom-anon-id-plugin'
import { everflowAnalyticsPlugin } from './plugins/everflow-plugin'
import { fullstoryAnalyticsPlugin } from './plugins/fullstory-plugin'
import { growthbookAnalyticsPlugin } from './plugins/growthbook-plugin'
import { segmentPlugin } from './plugins/segment-plugin'
import { theatricalAngelPartytownPlugin } from './plugins/theatrical-angel-partytown-plugin'
import { getFbcCookie, getFbpCookie, regionName } from './utils'

const analyticsInstance: AnalyticsInstance = AnalyticsModule({
  app: appName,
  plugins:
    typeof window === 'undefined'
      ? []
      : [
          customAnonIdPlugin(),
          everflowAnalyticsPlugin(),
          segmentPlugin(),
          theatricalAngelPartytownPlugin(),
          growthbookAnalyticsPlugin(),
          fullstoryAnalyticsPlugin(),
          branchAnalyticsPlugin(),
        ],
})

export const analytics: AnalyticsInstance = {
  ...analyticsInstance,
  identify: async (userId, traits, options, callback) => {
    if (typeof window === 'undefined') {
      throw new Error('Analytics is not available on the server')
    }
    const { context, user } = analytics.getState()
    return analyticsInstance.identify(
      userId,
      {
        ...(user?.traits || {}),
        rid: getRID(), // ? braze requires rid
        did: user.anonymousId, // ? braze requires did
        anonymousId: user.anonymousId,
        userId: userId || null,
        locale: context.locale,
        operatingSystem: context.os.name,
        referrer: context.referrer,
        timezone: context.timezone,
        userAgent: context.userAgent,
        isMobile,
        isAndroid,
        isIOS,
        fbc: getFbcCookie() || null,
        fbp: getFbpCookie() || null,
        ...(traits || {}),
      },
      options,
      callback,
    )
  },
  page: (data, options, callback) => {
    if (typeof window === 'undefined') {
      throw new Error('Analytics is not available on the server')
    }
    const { context, user } = analytics.getState()
    return analyticsInstance.page(
      omitUndefinedAndNulls({
        buildId,
        project: appName,
        utm: getObjectFromSessionStorage('originalUtms', null),
        user_id: user.userId,
        session_id: context.sessionId,
        anonymous_id: user.anonymousId,
        region: regionName(user.traits?.region),
        ...(data || {}),
      }) || undefined,
      options,
      callback,
    )
  },
  track: (
    eventName: string,
    payload?: Record<string, unknown>,
    options?: Record<string, unknown>,
    callback?: (...params: unknown[]) => unknown,
  ) => {
    if (typeof window === 'undefined') {
      throw new Error('Analytics is not available on the server')
    }
    const { context, user } = analytics.getState()

    const fbc = getFbcCookie()
    const fbp = getFbpCookie()

    return analyticsInstance.track(
      eventName,
      omitUndefineds({
        buildId,
        project: appName,
        affiliation: 'Angel Web',
        user_id: user.userId,
        session_id: context.sessionId,
        anonymous_id: user.anonymousId,
        region: regionName(user.traits?.region),
        utm: getObjectFromSessionStorage('originalUtms', null),
        browser_fingerprint_id: localStorage.getItem('browser_fingerprint_id'), // ? branch
        angel_env: getAngelEnvironment(),
        fbc,
        fbp,
        fb_login_id: user.traits?.fb_login_id,
        ...payload,
      }),
      options,
      callback,
    )
  },
}
