import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import { getCookie } from 'cookies-next'
import { ServerResponse } from 'http'
import jwtDecode from 'jwt-decode'
import { AUTH_JWT_COOKIE } from '@/constants/cookies'
import { GuildPermission, GuildPlan } from '@/types/codegen-federation'
import { reportErrorToBugsnag } from '../bugsnag'
import { getStringOrEmptyString, getValidString } from '../primitives/strings'
import { ServerRequest } from '../types'

interface DecodedToken {
  sub: string
}

export function getAccessTokenFromServer(req: ServerRequest, res?: ServerResponse): string {
  return getStringOrEmptyString(getValidString(getCookie(AUTH_JWT_COOKIE, { req, res })))
}

export function getUserUuidFromJwt(req: ServerRequest, res?: ServerResponse) {
  const accessToken = getAccessTokenFromServer(req, res)
  const decoded = accessToken ? jwtDecode<DecodedToken>(accessToken as string) : null
  return decoded?.sub
}

export async function getUserGuildStatus(uuid: string | null): Promise<GuildStatus | null> {
  if (!uuid) return null

  if (typeof window !== 'undefined') {
    throw new Error('getUserGuildStatus should only be called on the server!')
  }

  if (!process.env.GUILD_NFT_URL || !process.env.GUILD_NFT_API_KEY) {
    throw new Error('Missing GUILD_NFT_URL or GUILD_NFT_API_KEY environment variables!')
  }

  const baseUrl = `${process.env.GUILD_NFT_URL}/guildUser/${uuid}`

  try {
    const response = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Authorization: process.env.GUILD_NFT_API_KEY,
      },
    })

    if (!response.ok) {
      return null
    }

    const data: GuildStatus = await response.json()
    return data
  } catch (error) {
    // eslint-disable-next-line no-console
    reportErrorToBugsnag(`Failed to fetch guild user status! ${error}`)
    return null
  }
}

export interface GuildStatus {
  uuid: string
  isGuildMember: boolean
  guildMemberStart: Date | null
  guildMemberEnd: Date | null
  guildMemberReason: string
  guildPermissions: GuildPermission[]
  guildPlan: GuildPlan
  guildRoles: {
    canVote: boolean
    hasEarlyAccess: boolean
    hasFreeTickets: boolean
    hasMerchDiscount: boolean
    hasChosenTickets: boolean
    hasFirstVote: boolean
    kycVerified: boolean
    kycFailed: boolean
  }
}

export const GET_USER_PUBLIC_PROFILE = gql`
  query GetUser($uuid: ID!, $fallbackToInitials: Boolean) {
    publicUserProfile(uuid: $uuid) {
      firstName
      lastName
      image(fallbackToInitials: $fallbackToInitials)
      id
      city
      state
      country
    }
  }
`

export const getUserPublicProfile = async (
  client: ApolloClient<NormalizedCacheObject>,
  fallbackToInitials = true,
  uuid?: string,
) => {
  const { data } = await client.query({
    query: GET_USER_PUBLIC_PROFILE,
    variables: { uuid, fallbackToInitials },
    errorPolicy: 'all',
  })
  return data.publicUserProfile
}

export interface UserPublicProfile {
  firstName: string
  lastName: string
  image: string
  id: string
  city?: string
  state?: string
  country?: string
}
