import {
  identify as fullstoryIdentify,
  init as fullstoryInit,
  isInitialized as isFullstoryInitialized,
} from '@fullstory/browser'
import { paths } from '@/constants/paths'
import { isProductionEnvironment } from '@/utils/environment-utils'
import { getBooleanFromSessionStorage, writeToSessionStorage } from '@/utils/session-storage'
import { getIsAngelEmployeeUser } from '@/utils/users'
import { namedLogger } from '../utils'
import { type AnalyticsPlugin } from './types'

const FULL_STORY_SAMPLE_RATE = 0.3

type AnalyticsEventParams<Payload extends object = object> = {
  payload: Payload
}

const logger = namedLogger('fullstory Analytics Plugin', {
  // debug: true,
})

export function fullstoryAnalyticsPlugin() {
  const plugin: AnalyticsPlugin = {
    name: 'fullstory',

    page({ payload }: { payload?: { properties?: { path?: string } } }) {
      logger.debug('page', { payload })
      if (typeof window === 'undefined') return
      logger.debug('path', payload?.properties?.path)
      if (!payload?.properties?.path) return
      const shouldTrack = shouldTrackFullStoryImpression(payload.properties.path)
      logger.debug('shouldTrack', { shouldTrack })
      if (!shouldTrack) return

      logger.debug('initializing')

      fullstoryInit({
        orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID || '',
        devMode: !isProductionEnvironment(),
      })
    },

    identify: ({
      payload,
    }: AnalyticsEventParams<{
      userId?: string
      anonymousId?: string
      traits?: {
        email?: string
        displayName?: string
        hasVPPAConsent?: boolean
      }
    }>) => {
      const { userId, traits } = payload
      if (!userId) return

      const isAngelEmployeeUser = getIsAngelEmployeeUser(traits?.email)
      if (isAngelEmployeeUser) return

      if (!isFullstoryInitialized()) return

      const custom = traits?.hasVPPAConsent ? { displayName: traits.displayName, email: traits.email || '' } : undefined

      fullstoryIdentify(userId, custom)
    },

    loaded() {
      logger.debug('loaded')
      return true
    },
  }

  return plugin
}

function shouldTrackFullStoryImpression(path: string): boolean {
  if (path !== `${paths.guild.join}/angel-studios` && path !== paths.guild.join) return false

  // if this users session has already been tracked, don't track again
  if (getBooleanFromSessionStorage('fullStoryTracked')) return false

  if (Math.random() < FULL_STORY_SAMPLE_RATE) {
    writeToSessionStorage('fullStoryTracked', true)
    return true
  }

  return false
}
