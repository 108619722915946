import { WidenPrimitives } from '@growthbook/growthbook'
import { setCookie } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { Durations } from '@/constants/durations'
import { AppFeatures } from '@/experimentation/app-features'
import { getGrowthbook } from '@/experimentation/utils'
import { getAnonymousId } from '@/services/requests'
import { track } from '@/utils/SegmentNode'
import { getUserUuidFromJwt } from '@/utils/users/usersServer'

export const initializeGrowthbookOnServer = async (
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
) => {
  const { anonymousId, shouldSetCookie } = getAnonymousId(req.cookies)
  if (shouldSetCookie) {
    setCookie(SEGMENT_ANONYMOUS_ID_COOKIE, anonymousId, {
      maxAge: Durations.TEN_YEARS_IN_SECONDS,
      path: '/',
      res,
      req,
    })
  }

  const growthbook = getGrowthbook(
    {
      subscribeToChanges: false,
      backgroundSync: false,
    },
    async (eventName, payload) => {
      return await track(eventName, { anonymousId, ...payload })
    },
  )

  // Give GB two attempts to initialize
  const gbRes = await growthbook.init()
  if (gbRes.error) await growthbook.init()

  const userId = getUserUuidFromJwt(req, res) ?? null

  await growthbook.setAttributes({ id: anonymousId, userId })
  return growthbook
}

export const setupServerExperiments = async (
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
) => {
  const growthbook = await initializeGrowthbookOnServer(req, res)
  return {
    getFeatureValue: growthbook.getFeatureValue.bind(growthbook),
    destroy: growthbook.destroy.bind(growthbook),
    anonymousId: growthbook.getAttributes().id,
  }
}

const getExperimentValueFromQuery = (fromQuery: string) => {
  if (fromQuery === 'true') return true
  if (fromQuery === 'false') return false
  if (Number.isFinite(Number(fromQuery))) return Number(fromQuery)
  return fromQuery
}

export const runExperimentOnServer = async <T extends keyof AppFeatures>(
  experimentKey: T,
  defaultValue: AppFeatures[T],
  req: IncomingMessage & { cookies: Partial<{ [p: string]: string }> },
  res: ServerResponse,
): Promise<WidenPrimitives<AppFeatures[T]>> => {
  const url = new URL(req.url as string, `http://${req.headers.host}`)
  const fromQuery = url.searchParams.get(experimentKey)
  if (fromQuery !== null) {
    return getExperimentValueFromQuery(fromQuery) as WidenPrimitives<AppFeatures[T]>
  }

  const { getFeatureValue, destroy } = await setupServerExperiments(req, res)
  const variation = getFeatureValue(experimentKey, defaultValue)

  destroy()
  return variation
}
