import { NextApiRequest, NextApiResponse } from 'next'
import qs from 'query-string'
import { SessionCache } from '@/auth'
import { getWebClient } from '@/services/ApolloClient'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import { logger } from '@/utils/logging'
import ClientConfig from '../ClientConfig'
import { getProtocol } from './util'

export const handler =
  (config: ClientConfig, sessionCache: SessionCache) => async (req: NextApiRequest, res: NextApiResponse) => {
    if (req.method === 'GET') {
      try {
        const protocol = getProtocol(req)
        const client = getWebClient()

        let returnURL = `${protocol}://${req.headers.host}/`

        const returnToQuery = getItemOrFirstEntry(req.query.returnTo)

        if (returnToQuery) {
          const parsed = Buffer.from(returnToQuery, 'base64').toString('utf-8')
          returnURL = parsed?.startsWith('http') ? parsed : `${protocol}://${req.headers.host}${parsed}`
        }

        const isAuthenticated = await sessionCache.isAuthenticated(req, res)
        if (!isAuthenticated) {
          const q = { client_id: config.clientId, return_to: returnURL || '' }
          const query = qs.stringify(q)
          logger().info('User is not authenticated. Redirecting to logout URI', { query, req, res })
          try {
            await sessionCache.delete(req, res)
          } catch (error) {
            logger().warn('Error deleting session for unauthenticated user', { error, query, req, res })
          }
          res.redirect(`${config.logoutUri}?${query}`)
          await client.resetStore()
          return
        }

        await sessionCache.delete(req, res)

        const q = { client_id: config.clientId, return_to: returnURL }
        const query = qs.stringify(q)

        logger().info('User is authenticated, session deleted, redirecting to logout URI', { query, req, res })
        res.redirect(`${config.logoutUri}?${query}`)
        await client.resetStore()
      } catch (error) {
        logger().error('Error during logout process', { error })
        res.status(500).end()
      }
    } else {
      logger().warn('Invalid method for logout handler', { method: req.method })
      res.status(405).end()
    }
  }

export default handler
