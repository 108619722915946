import { gql } from '@apollo/client'

export const GET_USER_PROFILE_QUERY = gql`
  query userProfile {
    user {
      id
      email
      uuid
      isAdmin
      paymentPlatform
      groups {
        name
      }
      profile {
        firstName
        lastName
        image(fallbackToInitials: true)
        city
        state
        country
        isChosenInvestorSceneClaimed
        isChosenPifferSceneClaimed
        isChosenSubscriberSceneClaimed
        isHosTicketSceneClaimed
      }
      userAchievements {
        achievedAt
        achievementType
        iconPath
        name
        projectSlug
      }
      socialAuth(filters: { provider: "facebook" }) {
        socialUid
        provider
      }
    }
  }
`

export const GET_USER_EXPRESSED_INTEREST = gql`
  query userExpressedInterest {
    user {
      expressionsOfInterest {
        amount
        initiative {
          guid
        }
      }
      isGuildMember
      isInvestor
    }
  }
`
export const GET_USER_FINANCIALS = gql`
  query userFinancials {
    user {
      isInvestor
      profile {
        isChosenInvestor
        isChosenSubscriber
        isChosenPiffer
      }
    }
  }
`

export const USER_GUILD_MEMBERSHIP = gql`
  query userGuildMembership {
    user {
      id
      isGuildMember
      guildMemberReason
      guildMemberStart
      guildPlan
      email
      guildRoles {
        canVote
        hasEarlyAccess
        hasFreeTickets
        hasChosenTickets
        hasMerchDiscount
      }
    }
  }
`

export const USER_GUILD_ROLES_AND_PERMISSSIONS = gql`
  query userGuildRolesAndPermissions {
    user {
      id
      roles
      guildMemberReason
      email
      permissions
    }
  }
`

export const UPDATE_USER_PROFILE_MUTATION = gql`
  mutation updateUserProfile($input: UpdateUserProfileInput!) {
    updateUserProfile(input: $input) {
      city
      country
      firstName
      image(fallbackToInitials: true)
      lastName
      state
    }
  }
`

export const GET_USER_GUILD_ROLES = gql`
  query GuildRoles {
    user {
      id
      guildRoles {
        canVote
        hasEarlyAccess
        hasFreeTickets
        hasChosenTickets
        hasMerchDiscount
      }
    }
  }
`

export const GET_USER_GUILD_TICKETS_AVAILABLE = gql`
  query getGuildUserTicketsAvailableForSlug($input: NumberOfGuildTicketsAvailableInput!) {
    numberOfGuildTicketsAvailable(input: $input) {
      count
    }
  }
`

export const GET_USER_GUILD_TICKETS_DISCOUNT_CODES = gql`
  query discountsListGuildCodes($input: DiscountsListGuildCodesInput!) {
    discountsListGuildCodes(input: $input) {
      discounts {
        id
        code
        name
        theatricalSlug
        category
      }
    }
  }
`
