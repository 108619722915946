/* istanbul ignore file */

// Note that this is a .js file, not .ts, because it is imported by src/services/RedirectsManager/RedirectsManager.js, which is imported by next.config.js

const slugs = {
  // this is used for us to port over pay it forward subscriptions
  angelOldChosen: 'angel-old-chosen',
  afterDeath: 'after-death',
  angelGuild: 'angel-guild',
  angelStudios: 'angel-studios',
  aWeekAwaySeries: 'a-week-away-series',
  axiom: 'axiom',
  bethlehem: 'bethlehem',
  betweenBorders: 'between-borders',
  beyondTheMedal: 'beyond-the-medal',
  bonhoeffer: 'bonhoeffer',
  braveTheDark: 'brave-the-dark',
  breakingIntoBeautiful: 'breaking-into-beautiful',
  cabrini: 'cabrini',
  david: 'david',
  dryBar: 'dry-bar-best-of',
  dryBarToons: 'dry-bar-toons',
  fablehaven: 'fablehaven',
  faithOfAngels: 'faith-of-angels',
  fellowship: 'fellowship',
  forKingAndCountryADrummerBoyChristmasLive: 'for-king-and-country-a-drummer-boy-christmas-live',
  forYourConsideration: 'for-your-consideration',
  freelancers: 'freelancers',
  gabrielAndTheGuardians: 'gabriel-and-the-guardians',
  godMadeCrab: 'god-made-crab',
  godspeed: 'godspeed',
  goodThings: 'good-things',
  hisOnlySon: 'his-only-son',
  homestead: 'homestead',
  homesteadFamilySurvival: 'homestead-family-survival',
  homesteadSeries: 'homestead-series',
  homeToHarmony: 'home-to-harmony',
  hoovey: 'hoovey',
  inLawfullyYours: 'in-lawfully-yours',
  insideAngelStudios: 'inside-angel-studios',
  jacob: 'jacob',
  jungleBeat: 'jungle-beat',
  justLetGo: 'just-let-go',
  kingOfKings: 'king-of-kings',
  lightwise: 'lightwise',
  liveNotByLies: 'live-not-by-lies',
  lostOnAMountainInMaine: 'lost-on-a-mountain-in-maine',
  miracles: 'miracles',
  offTheCouchIntoCreation: 'off-the-couch-into-creation',
  oldManAndTheDunes: 'old-man-and-the-dunes',
  operationTripleTake: 'operation-triple-take',
  playingForEternity: 'playing-for-eternity',
  pocketOfHope: 'pocket-of-hope',
  possumTrot: 'possum-trot',
  quitters: 'quitters',
  quokkasWildLife: 'quokkas-wild-life',
  redemptionOfHenryMyers: 'redemption-of-henry-myers',
  riot: 'riot',
  riotAndTheDanceSeries: 'riot-and-the-dance-series',
  ruleBreakers: 'rule-breakers',
  sabina: 'sabina',
  sevenDaysInUtopia: 'seven-days-in-utopia',
  sight: 'sight',
  somebodysGottaDoIt: 'somebodys-gotta-do-it',
  somethingToStandForWithMikeRowe: 'something-to-stand-for-with-mike-rowe',
  soundOfFreedom: 'sound-of-freedom',
  soundOfHopeTheStoryOfPossumTrot: 'sound-of-hope-the-story-of-possum-trot',
  standingUp: 'standing-up',
  stagingForContent: 'staging-for-content',
  surprisedByOxford: 'surprised-by-oxford',
  sweetwater: 'sweetwater',
  telegram: 'telegram',
  tempest: 'tempest',
  testament: 'testament',
  testamentSeries: 'testament-series',
  the1916Project: '1916-project',
  theBlind: 'the-blind',
  theChosen: 'the-chosen',
  theGardenCartoon: 'the-garden-cartoon',
  theGermanKing: 'the-german-king',
  theLastRifleman: 'the-last-rifleman',
  theLastRodeo: 'last-rodeo',
  theLunaticFarmer: 'the-lunatic-farmer',
  thePowerOfAPrayingWife: 'the-power-of-a-praying-wife',
  theShift: 'the-shift',
  theWay: 'the-way',
  theWorldWeMake: 'world-we-make',
  toEndAllWars: 'to-end-all-wars',
  triumphOfTheHeart: 'triumph-of-the-heart',
  truthAndConviction: 'truth-and-conviction',
  truthAndTreason: 'truth-and-treason',
  truthAndTreasonSeries: 'truth-and-treason-series',
  tuttleTwins: 'tuttle-twins',
  twentyThreeBlast: '23-blast',
  unexplainable: 'unexplainable',
  vindication: 'vindication',
  wayfinders: 'wayfinders',
  wingfeatherSaga: 'wingfeather-saga',
  youngDavid: 'young-david',
  youngWashington: 'young-washington',
}

module.exports = {
  slugs,
}
